var render = function render(){var _vm=this,_c=_vm._self._c;return _c('grid-view',{attrs:{"title":"Инструкции к работе","create":"","create-title":"Создать инструкцию","url-create":"/hint/create","url-update":"/hint/update/:id","store-module":"hint","headers":[
        { text: 'ID', value: 'id', width: 1 },
        { text: 'Предмет', value: 'subject', width: 1, sortable: false },
        { text: 'Класс', value: 'grade', width: 1, sortable: false },
        { text: '', value: 'edit', width: 1, sortable: false },
        { text: '', value: 'remove', width: 1, sortable: false }
    ],"model-fields":['name'],"format":_vm.formatItems}})
}
var staticRenderFns = []

export { render, staticRenderFns }